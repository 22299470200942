import React from "react";
import Image from "react-bootstrap/Image";

class Following2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      following: false,
    };
    this.toggleFollowing = this.toggleFollowing.bind(this);
  }

  toggleFollowing() {
    this.setState({
      following: !this.state.following,
    });
  }

  render() {
    return (
      <div style={{ marginBottom: "30px" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "20px",
          }}>
          <div style={{ marginRight: "10px" }}>
            <Image
              src="https://cdn.pixabay.com/photo/2023/08/17/08/55/ice-cream-8195933_1280.png"
              style={{
                width: "50px",
                height: "50px",
                objectFit: "cover",
                borderRadius: "50%",
              }}
            />
          </div>
          <div
            className="d-flex flex-column align-items-start pengikut"
            style={{ fontSize: "16px" }}>
            <span style={{ marginLeft: "10px", fontWeight: "bold" }}>
              Orang Asing
            </span>
            <span style={{ marginLeft: "10px", textAlign: "left" }}>
              Terlalu sulit untuk bersama, selamanya menggapai cinta
            </span>
          </div>

          <div style={{ marginLeft: "auto" }}>
            <button
              type="button"
              className={`btn ${
                this.state.following ? "btn-dark" : "btn-primary"
              }`}
              style={{ marginTop: "10px" }}
              onClick={this.toggleFollowing}>
              {this.state.following ? "Batal" : "Ikuti"}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default Following2;
