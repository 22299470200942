import React from "react";
import NavbarHome from "../home/Navbar";
import styles from "./LayoutHome.module.css";

const LayoutHome = ({ children }) => {
  return (
    <div>
      <NavbarHome />
      {children}
    </div>
  );
};

export default LayoutHome;