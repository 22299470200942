import React from "react";
import Card from "react-bootstrap/Card";

class Populer extends React.Component {
  render() {
    return (
      <Card style={{ width: "100%" }}>
        <Card.Header>Featured</Card.Header>
        <Card.Img
          variant="top"
          src="https://cdn.pixabay.com/photo/2023/09/17/04/05/woman-8257787_1280.jpg"
          style={{
            width: "100%",
            height: "200px",
            objectFit: "cover",
            borderRadius: "10px",
          }}
        />
        <Card.Body>
          <Card.Title>Card Title</Card.Title>
          <Card.Text>
            Some quick example text to build on the card title and make up the
            bulk of the card's content.
          </Card.Text>
        </Card.Body>
      </Card>
    );
  }
}
export default Populer;