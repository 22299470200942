import React from "react";
import LayoutHome from "../components/layouts/LayoutHome";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";

class About extends React.Component {
  render() {
    return (
      <LayoutHome>
        <Container>
          <Row>
            <Col xs={12}>
              <h3 className="text-center">Tentang Saya</h3>
              <hr className="my-4" />
            </Col>
          </Row>
          <Row className="justify-content-center">
            {" "}
            <Col md={{ span: 4, offset: 2 }} xs={12} className="mb-4 text-center">
              {" "}
              <Image
                src="https://irhamkaraman.my.id/storage/avatar/20240619_160012_fotoku.jpg"
                fluid
                roundedCircle
                style={{ width: "200px", height: "200px" }}
              />
            </Col>
            <Col md={6} xs={12} className="text-center">
              {" "}
              <p className="text-sm">
                Nama saya Lazuardi Irham Karaman, biasalah saya Irham. Saya
                seorang mahasiswa aktif di Universitas Muhammadiyah Ponorogo
                (UMPO) yang berada di Ponorogo. Saya adalah seorang web
                developer yang sedang belajar dan mengembangkan skill saya
                dibidang ini.
              </p>
              <p className="text-sm">
                Saya senang dengan teknologi dan memiliki minat yang tinggi pada
                pengembangan website. Saya sangat senang ketika dapat membantu
                orang-orang untuk membuat website yang bagus.
              </p>
              <p className="text-sm">
                Saya juga senang dengan musik dan memiliki minat yang tinggi
                pada musik rock. Saya sangat senang ketika dapat menikmati
                konser musik dan membantu orang-orang dalam hal musik.
              </p>
              <p className="text-sm">
                Saya senang dengan hidup dan bersama-sama dengan teman-teman
                saya dalam hidup ini.
              </p>
            </Col>
          </Row>
        </Container>
      </LayoutHome>
    );
  }
}
export default About;
