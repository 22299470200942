import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./home/Home";
import About from "./home/About";
import ErrorPage from "./components/ErrorPage";

function AppRouter() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </Router>
  );
}

export default AppRouter;
