import React from "react";
import Image from "react-bootstrap/Image";

class Following extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      following: false,
    };
    this.toggleFollowing = this.toggleFollowing.bind(this);
  }

  toggleFollowing() {
    this.setState({
      following: !this.state.following,
    });
  }

  render() {
    return (
      <div style={{ marginBottom: "30px" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "20px",
          }}>
          <div style={{ marginRight: "10px" }}>
            <Image
              src="https://cdn.pixabay.com/photo/2020/05/17/20/21/cat-5183427_1280.jpg"
              style={{
                width: "50px",
                height: "50px",
                objectFit: "cover",
                borderRadius: "50%",
              }}
            />
          </div>
          <div
            className="d-flex flex-column align-items-start pengikut"
            style={{ fontSize: "16px" }}>
            <span style={{ marginLeft: "10px", fontWeight: "bold" }}>
              Lazuardi Irham Karaman
            </span>
            <span style={{ marginLeft: "10px", textAlign: "left" }}>
              Seorang pelajar aktif di Universitas Muhammadiyah Ponorogo
            </span>
          </div>

          <div>
            <button
              type="button"
              className={`btn ${
                this.state.following ? "btn-dark" : "btn-primary"
              }`}
              style={{ marginLeft: "30px", marginTop: "10px" }}
              onClick={this.toggleFollowing}>
              {this.state.following ? "Batal" : "Ikuti"}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default Following;
