import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Collapse from "react-bootstrap/Collapse";
import Rekomendasi from "./HeaderColumn1LIB/rekomendasi";
import Rekomendasi2 from "./HeaderColumn1LIB/rekomendasi2";
import Following from "./HeaderColumn1LIB/following";
import Following2 from "./HeaderColumn1LIB/following2";
import Populer from "./HeaderColumn1LIB/populer";


function HeaderColumn1Home() {
  const [open, setOpen] = useState(false);
  const [forYouOpen, setForYouOpen] = useState(false);
  const [populerOpen, setPopulerOpen] = useState(false);

  const rekomendasi = () => {
    setOpen((prevOpen) => !prevOpen);
    if (forYouOpen) {
      setForYouOpen(false);
    } else if (populerOpen) {
      setPopulerOpen(false);
    }
  };

  const forYou = () => {
    setForYouOpen((prevOpen) => !prevOpen);
    if (open) {
      setOpen(false);
    } else if (populerOpen) {
      setPopulerOpen(false);
    }
  };

  const populer = () => {
    setPopulerOpen((prevOpen) => !prevOpen);
    if (open) {
      setOpen(false);
    } else if (forYouOpen) {
      setForYouOpen(false);
    }
  };

  useEffect(() => {
    setOpen(true);
    setForYouOpen(false);
    setPopulerOpen(false);
  }, []);

  return (
    <div className="header-column1-home">
      <ul
        className="list-unstyled d-flex flex-row"
        style={{ borderWidth: "2px" }}>
        <li className="d-flex align-items-center mb-2 ml-4 mr-4 border-bottom border-light">
          <Button
            onClick={rekomendasi}
            aria-controls="rekomendasi"
            aria-expanded={open}
            variant="link"
            style={{ color: "#808080", padding: 0 }}>
            <span>+</span>
          </Button>
        </li>
        <li
          className="d-flex align-items-center mb-2 ml-4 mr-4 border-bottom border-light"
          style={{ color: "#808080" }}>
          <Button
            onClick={forYou}
            aria-controls="forYou"
            aria-expanded={forYouOpen}
            variant="link"
            style={{ color: "#808080", padding: 0 }}>
            <span>Pengikut</span>
          </Button>
        </li>
        <li
          className="d-flex align-items-center mb-2 ml-4 mr-4 border-bottom border-light"
          style={{ color: "#808080" }}>
          <Button
            onClick={populer}
            aria-controls="populer"
            aria-expanded={populerOpen}
            variant="link"
            style={{ color: "#808080", padding: 0 }}>
            <span>Populer</span>
          </Button>
        </li>
      </ul>

      <Collapse in={open}>
        <div
          id="rekomendasi"
          className="mt-2 text-left header-column1-home-container"
          style={{ marginRight: "50px", marginLeft: "20px" }}>
          <Rekomendasi />
          <hr className="my-4" />
          <Rekomendasi2 />
          <hr className="my-4" />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
            }}>
            <Button
              variant="dark"
              className="rounded-pill my-3"
              style={{ marginTop: "10px", fontFamily: "Poppins", fontSize: "14px", padding: "10px 20px" }}>
              Baca Selengkapnya
            </Button>
          </div>
        </div>
      </Collapse>

      <Collapse in={forYouOpen}>
        <div
          id="forYou"
          className="mt-2"
          style={{
            marginRight: "20px",
            marginTop: "20px",
            marginBottom: "50px",
          }}>
          <Following />
          <Following2 />
        </div>
      </Collapse>

      <Collapse in={populerOpen}>
        <div
          style={{
            marginRight: "50px",
            marginLeft: "50px",
            marginTop: "20px",
            marginBottom: "50px",
          }}>
          <Populer />
        </div>
      </Collapse>
    </div>
  );
}

export default HeaderColumn1Home;
