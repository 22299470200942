import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import ErrorPageSvg from "./ErrorPage.svg";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";

export default function ErrorPage() {
  return (
    <Container className="d-flex justify-content-center align-items-center vh-100">
      <Row className="text-center justify-content-md-center">
        <Col md={6} xs={12}>
          <Image src={ErrorPageSvg} style={{ width: "100%" }} />
          <h1 className="mt-4">Lagi Mencari Apa?</h1>
          <p>Tidak ada apa-apa.</p>
          <Button className="btn btn-secondary">
            <Link to="/" className="text-white">Kembali ke Beranda</Link>
          </Button>
        </Col>
      </Row>
    </Container>
  );
}
