import React from "react";
import LayoutHome from "../components/layouts/LayoutHome";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import HeaderColumn1Home from "../components/home/HeaderColumn1Home";
import HeaderColumn2Home from "../components/home/HeaderColumn2Home";
import Services from "../components/home/Services";
import "./global.css";

class Home extends React.Component {
  render() {
    return (
      <LayoutHome>
        <Container className="text-center">
          <h2>PenjarahKOS</h2>
          <p>Fullstack Developer</p>
          <Row className="content">
            <Col md={8}>
              <HeaderColumn1Home />
            </Col>
            <Col md={4} className="border-left">
              <HeaderColumn2Home />
            </Col>
          </Row>
        </Container>
        <Container>
          <Row>
            <Col>
              <Services />
            </Col>
          </Row>
        </Container>
      </LayoutHome>
    );
  }
}

export default Home;
