import React from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import style from "./homeComponents.css";

class NavbarHome extends React.Component {
  render() {
    return (
      <Navbar
        expand="lg"
        className="bg-body-primary mb-4 border-bottom border-3">
        <Container>
          <Navbar.Brand href="/" className="judul">
            React App
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto" activeKey="/">
              <Nav.Link as={Link} to="/">
                Beranda
              </Nav.Link>
              <Nav.Link as={Link} to="/about">
                Tentang
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    );
  }
}

export default NavbarHome;
