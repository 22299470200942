import React from "react";
import Image from "react-bootstrap/Image";

const Rekomendasi = () => {
  return (
    <div style={{ marginBottom: "30px" }}>
      <div
        style={{ display: "flex", alignItems: "center", marginBottom: "20px" }}>
        <div style={{ marginRight: "10px" }}>
          <Image
            src="https://cdn.pixabay.com/photo/2020/05/17/20/21/cat-5183427_1280.jpg"
            style={{
              width: "30px",
              height: "30px",
              objectFit: "cover",
              borderRadius: "50%",
            }}
          />
        </div>
        <span style={{ marginLeft: "0px" }} className="namaOrang">
          Lazuardi Irham Karaman
        </span>
      </div>
      <h3
        style={{
          fontFamily: "Poppins",
          fontSize: "30px",
          marginBottom: "20px",
          fontWeight: "bold",
        }}>
        I Found the Best Apps for Productivity in 2024
      </h3>

      <p
        style={{
          fontFamily: "Poppins",
          fontSize: "16px",
          marginBottom: "20px",
        }}>
        <span style={{ fontWeight: "bold" }}>Productivity</span> is a key factor
        in our success. Here are some of the apps that have helped us in our
        journey.
      </p>

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div
          style={{
            display: "flex",
            fontFamily: "arial",
            fontSize: "12px",
          }}>
          <p>Senin, 8 Juni 2024</p>
          <div style={{ marginLeft: "30px" }}>
            <i class="fa-solid fa-heart"></i>
            <span style={{ marginLeft: "5px" }}>100</span>
          </div>
          <div style={{ marginLeft: "30px" }}>
            <i class="fa-solid fa-share"></i>
            <span style={{ marginLeft: "5px" }}>20</span>
          </div>
        </div>

        <div
          style={{ display: "flex", justifyContent: "end", fontSize: "16px" }}>
          <div style={{ marginLeft: "50px" }}>
            <i class="fa-solid fa-bookmark"></i>
          </div>
          <div style={{ marginLeft: "50px" }}>
            <i class="fa-solid fa-list"></i>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Rekomendasi;
