import React from "react";
import { Link } from "react-router-dom";

function Kolom2() {
  return (
    <div>
      <div
        style={{
          background: `url("https://img.plasmic.app/img-optimizer/v1/img?src=https%3A%2F%2Fimg.plasmic.app%2Fimg-optimizer%2Fv1%2Fimg%2F0dbeb780a498094114ef3376f5213dda.webp&q=75&f=webp")`,
          backgroundSize: "15%",
          backgroundPosition: "left center",
          backgroundRepeat: "no-repeat",
          height: "170px",
          position: "relative",
          zIndex: -1,
        }}>
        <h4
          style={{
            fontFamily: "Poppins",
            fontWeight: "bold",
            paddingTop: "70px",
            marginLeft: "30px",
          }}>
          Gambar Random dibuat menjadi Infinity Scroll
        </h4>
      </div>
      <div style={{ marginTop: "-30px" }}>
        <p
          style={{
            fontFamily: "Poppins",
            fontSize: "16px",
            marginTop: "30px",
            marginLeft: "30px",
          }}
        >
          Dalam setiap gambar, ada cerita yang belum terbaca, gambar itu bukan hanya
          foto, tapi juga perjalanan kita.
        </p>
        <Link
          to="/donasi"
          className="btn btn-secondary"
          style={{ marginTop: "20px", marginLeft: "30px" }}>
            Donasi
        </Link>
      </div>
    </div>
  );
}

export default Kolom2;
