import React from "react";
import InfinityScroll from "./ServiceLIB/infinityscroll";
import Kolom2 from "./ServiceLIB/kolom2";
import "./homeComponents.css";


class Services extends React.Component {
  render() {
    return (
      <div
        style={{
          marginBottom: "30px",
          marginTop: "100px",
          paddingLeft: "5%",
          paddingRight: "5%",
        }}>
        <h3 className="text-center">Infinity Scroll</h3>
        <p className="text-center text-secondary">Dalam setiap gambar, ada cerita yang belum terbaca</p>
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ marginTop: "50px", marginBottom: "50px" }}>
          <div className="d-flex flex-column flex-md-row">
            {" "}
            <InfinityScroll />
            <div
              style={{ width: "100%", paddingLeft: "5%", paddingRight: "5%", marginTop: "50px", height: "400px" }}>
              <Kolom2 />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Services;
