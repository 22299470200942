import React from "react";
import Image from "react-bootstrap/Image";

const Rekomendasi2 = () => {
  return (
    <div>
      <div
        style={{ display: "flex", alignItems: "center", marginBottom: "20px" }}>
        <div style={{ marginRight: "10px" }}>
          <Image
            src="https://cdn.pixabay.com/photo/2019/09/01/10/13/man-4444765_1280.jpg"
            style={{
              width: "30px",
              height: "30px",
              objectFit: "cover",
              borderRadius: "50%",
            }}
          />
        </div>
        <span style={{ marginLeft: "0px" }} className="namaOrang">
          Orang
        </span>
      </div>
      <h3
        style={{
          fontFamily: "Poppins",
          fontSize: "30px",
          marginBottom: "20px",
          fontWeight: "bold",
        }}>
        Menggunakan React JS untuk Membuat Aplikasi Web
      </h3>

      <p
        style={{
          fontFamily: "Poppins",
          fontSize: "16px",
          marginBottom: "20px",
        }}>
        Dengan menggunakan React JS, kita dapat membuat aplikasi web yang sangat
        mudah.
      </p>

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div
          style={{
            display: "flex",
            fontFamily: "arial",
            fontSize: "12px",
          }}>
          <p>Jumat, 1 Juni 2024</p>
          <div style={{ marginLeft: "30px" }}>
            <i class="fa-solid fa-heart"></i>
            <span style={{ marginLeft: "5px" }}>1rb</span>
          </div>
          <div style={{ marginLeft: "30px" }}>
            <i class="fa-solid fa-share"></i>
            <span style={{ marginLeft: "5px" }}>512</span>
          </div>
        </div>

        <div
          style={{ display: "flex", justifyContent: "end", fontSize: "16px" }}>
          <div style={{ marginLeft: "50px" }}>
            <i class="fa-solid fa-bookmark"></i>
          </div>
          <div style={{ marginLeft: "50px" }}>
            <i class="fa-solid fa-list"></i>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Rekomendasi2;
