import React from "react";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";

class HeaderColumn2Home extends React.Component {
  render() {
    return (
      <div style={{ textAlign: "left", marginLeft: "20px", [window.innerWidth < 768 ? "marginTop" : ""]: window.innerWidth < 768 ? "50px" : "" }}>
        <div style={{ marginBottom: "40px" }}>
          <h6>Developer</h6>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              height: "50px",
            }}>
            {" "}
            <Image
              src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
              style={{ width: "30px", height: "30px", borderRadius: "50%" }}
            />
            <p
              style={{
                fontWeight: "bold",
                fontSize: "14px",
                marginLeft: "10px",
                marginTop: "0",
                marginBottom: "0",
              }}>
              Lazuardi Irham Karaman
            </p>
            <i
              className="fa fa-check-circle"
              style={{ color: "#007bff", marginLeft: "10px" }}></i>
          </div>
          <h5
            style={{
              marginTop: "10px",
              fontFamily: "Poppins",
              fontWeight: "bold",
            }}>
            Bergabung bersama kami dan nikmati pengalaman
          </h5>
        </div>
        <div style={{ marginBottom: "20px" }}>
          <h6>Rekomendasi Topik</h6>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "0",
              fontFamily: "Poppins",
            }}>
            <Button
              variant="outline-secondary"
              className="rounded-pill my-3"
              style={{
                fontSize: "14px",
              }}>
              Politik
            </Button>
            <Button
              variant="outline-secondary"
              className="rounded-pill my-3"
              style={{
                fontSize: "14px",
                marginLeft: "10px",
              }}>
              Pendidikan
            </Button>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "-20px",
              fontFamily: "Poppins",
            }}>
            <Button
              variant="outline-secondary"
              className="rounded-pill my-3"
              style={{
                fontSize: "14px",
              }}>
              Hiburan
            </Button>
            <Button
              variant="outline-secondary"
              className="rounded-pill my-3"
              style={{
                fontSize: "14px",
                marginLeft: "10px",
              }}>
              Olahraga
            </Button>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "-20px",
              fontFamily: "Poppins",
            }}>
            <Button
              variant="outline-secondary"
              className="rounded-pill my-3"
              style={{
                fontSize: "14px",
              }}>
              Teknologi
            </Button>
          </div>
        </div>
        <div style={{ marginBottom: "20px" }}>
          <h6>Berita Terkini</h6>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "20px",
            }}>
            <div>
              <Image
                src="https://cdn.pixabay.com/photo/2016/02/13/13/11/oldtimer-1197800_1280.jpg"
                style={{ width: "90px", height: "50px", borderRadius: "5px" }}
              />
            </div>
            <div style={{ marginLeft: "10px" }}>
              <h6
                style={{
                  marginTop: "0",
                  marginBottom: "0",
                  fontFamily: "Poppins",
                }}>
                Politik{" "}
                <span
                  style={{
                    fontSize: "12px",
                    fontFamily: "Poppins",
                    color: "gray",
                  }}>
                  • 2 hari yang lalu
                </span>
              </h6>
              <p
                style={{
                  marginTop: "0",
                  marginBottom: "0",
                  fontFamily: "Poppins",
                }}>
                Mengangkat Pertanyaan, Reformasi, dan Demokrasi
              </p>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "10px",
            }}>
            <div>
              <Image
                src="https://cdn.pixabay.com/photo/2012/08/06/00/53/bridge-53769_960_720.jpg"
                style={{ width: "90px", height: "50px", borderRadius: "5px" }}
              />
            </div>
            <div style={{ marginLeft: "10px" }}>
              <h6
                style={{
                  marginTop: "0",
                  marginBottom: "0",
                  fontFamily: "Poppins",
                }}>
                Kebangkitan{" "}
                <span
                  style={{
                    fontSize: "12px",
                    fontFamily: "Poppins",
                    color: "gray",
                  }}>
                  • 2 hari yang lalu
                </span>
              </h6>
              <p
                style={{
                  marginTop: "0",
                  marginBottom: "0",
                  fontFamily: "Poppins",
                }}>
                Mengangkat Pertanyaan, Reformasi, dan Demokrasi
              </p>
            </div>
          </div>

          <div style={{ marginTop: "40px" }}>
            <h6>Welcome</h6>
            <div style={{ marginTop: "10px" }}>
                <p style={{ fontFamily: "Poppins" }}>
                  Selamat datang👋 di Penjarahkos, Website ini merupakan hasil belajar React Native.
                </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default HeaderColumn2Home;
